exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-activated-confirm-js": () => import("./../../../src/pages/account-activated-confirm.js" /* webpackChunkName: "component---src-pages-account-activated-confirm-js" */),
  "component---src-pages-account-disabled-js": () => import("./../../../src/pages/account-disabled.js" /* webpackChunkName: "component---src-pages-account-disabled-js" */),
  "component---src-pages-account-locked-js": () => import("./../../../src/pages/account-locked.js" /* webpackChunkName: "component---src-pages-account-locked-js" */),
  "component---src-pages-account-unlock-email-sent-js": () => import("./../../../src/pages/account-unlock-email-sent.js" /* webpackChunkName: "component---src-pages-account-unlock-email-sent-js" */),
  "component---src-pages-activate-email-sent-js": () => import("./../../../src/pages/activate-email-sent.js" /* webpackChunkName: "component---src-pages-activate-email-sent-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-activation-invalid-js": () => import("./../../../src/pages/activation-invalid.js" /* webpackChunkName: "component---src-pages-activation-invalid-js" */),
  "component---src-pages-anr-js": () => import("./../../../src/pages/anr.js" /* webpackChunkName: "component---src-pages-anr-js" */),
  "component---src-pages-anr-terms-and-conditions-js": () => import("./../../../src/pages/anr-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-anr-terms-and-conditions-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-change-password-success-js": () => import("./../../../src/pages/change-password-success.js" /* webpackChunkName: "component---src-pages-change-password-success-js" */),
  "component---src-pages-complete-signup-js": () => import("./../../../src/pages/complete-signup.js" /* webpackChunkName: "component---src-pages-complete-signup-js" */),
  "component---src-pages-content-guidelines-js": () => import("./../../../src/pages/content-guidelines.js" /* webpackChunkName: "component---src-pages-content-guidelines-js" */),
  "component---src-pages-distribute-music-to-spotify-js": () => import("./../../../src/pages/distribute-music-to-spotify.js" /* webpackChunkName: "component---src-pages-distribute-music-to-spotify-js" */),
  "component---src-pages-epk-js": () => import("./../../../src/pages/epk.js" /* webpackChunkName: "component---src-pages-epk-js" */),
  "component---src-pages-get-music-on-spotify-js": () => import("./../../../src/pages/get-music-on-spotify.js" /* webpackChunkName: "component---src-pages-get-music-on-spotify-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-music-distribution-7-digital-js": () => import("./../../../src/pages/music-distribution/7digital.js" /* webpackChunkName: "component---src-pages-music-distribution-7-digital-js" */),
  "component---src-pages-music-distribution-amazon-music-js": () => import("./../../../src/pages/music-distribution/amazon-music.js" /* webpackChunkName: "component---src-pages-music-distribution-amazon-music-js" */),
  "component---src-pages-music-distribution-anghami-js": () => import("./../../../src/pages/music-distribution/anghami.js" /* webpackChunkName: "component---src-pages-music-distribution-anghami-js" */),
  "component---src-pages-music-distribution-apple-music-js": () => import("./../../../src/pages/music-distribution/apple-music.js" /* webpackChunkName: "component---src-pages-music-distribution-apple-music-js" */),
  "component---src-pages-music-distribution-beatport-js": () => import("./../../../src/pages/music-distribution/beatport.js" /* webpackChunkName: "component---src-pages-music-distribution-beatport-js" */),
  "component---src-pages-music-distribution-deezer-js": () => import("./../../../src/pages/music-distribution/deezer.js" /* webpackChunkName: "component---src-pages-music-distribution-deezer-js" */),
  "component---src-pages-music-distribution-facebook-js": () => import("./../../../src/pages/music-distribution/facebook.js" /* webpackChunkName: "component---src-pages-music-distribution-facebook-js" */),
  "component---src-pages-music-distribution-iheartradio-js": () => import("./../../../src/pages/music-distribution/iheartradio.js" /* webpackChunkName: "component---src-pages-music-distribution-iheartradio-js" */),
  "component---src-pages-music-distribution-imusica-js": () => import("./../../../src/pages/music-distribution/imusica.js" /* webpackChunkName: "component---src-pages-music-distribution-imusica-js" */),
  "component---src-pages-music-distribution-instagram-js": () => import("./../../../src/pages/music-distribution/instagram.js" /* webpackChunkName: "component---src-pages-music-distribution-instagram-js" */),
  "component---src-pages-music-distribution-itunes-js": () => import("./../../../src/pages/music-distribution/itunes.js" /* webpackChunkName: "component---src-pages-music-distribution-itunes-js" */),
  "component---src-pages-music-distribution-js": () => import("./../../../src/pages/music-distribution.js" /* webpackChunkName: "component---src-pages-music-distribution-js" */),
  "component---src-pages-music-distribution-juno-download-js": () => import("./../../../src/pages/music-distribution/juno-download.js" /* webpackChunkName: "component---src-pages-music-distribution-juno-download-js" */),
  "component---src-pages-music-distribution-napster-js": () => import("./../../../src/pages/music-distribution/napster.js" /* webpackChunkName: "component---src-pages-music-distribution-napster-js" */),
  "component---src-pages-music-distribution-netease-js": () => import("./../../../src/pages/music-distribution/netease.js" /* webpackChunkName: "component---src-pages-music-distribution-netease-js" */),
  "component---src-pages-music-distribution-pandora-js": () => import("./../../../src/pages/music-distribution/pandora.js" /* webpackChunkName: "component---src-pages-music-distribution-pandora-js" */),
  "component---src-pages-music-distribution-qobuz-js": () => import("./../../../src/pages/music-distribution/qobuz.js" /* webpackChunkName: "component---src-pages-music-distribution-qobuz-js" */),
  "component---src-pages-music-distribution-shazam-js": () => import("./../../../src/pages/music-distribution/shazam.js" /* webpackChunkName: "component---src-pages-music-distribution-shazam-js" */),
  "component---src-pages-music-distribution-soundcloud-js": () => import("./../../../src/pages/music-distribution/soundcloud.js" /* webpackChunkName: "component---src-pages-music-distribution-soundcloud-js" */),
  "component---src-pages-music-distribution-spotify-js": () => import("./../../../src/pages/music-distribution/spotify.js" /* webpackChunkName: "component---src-pages-music-distribution-spotify-js" */),
  "component---src-pages-music-distribution-tidal-js": () => import("./../../../src/pages/music-distribution/tidal.js" /* webpackChunkName: "component---src-pages-music-distribution-tidal-js" */),
  "component---src-pages-music-distribution-tik-tok-js": () => import("./../../../src/pages/music-distribution/tik-tok.js" /* webpackChunkName: "component---src-pages-music-distribution-tik-tok-js" */),
  "component---src-pages-music-distribution-tiktok-js": () => import("./../../../src/pages/music-distribution/tiktok.js" /* webpackChunkName: "component---src-pages-music-distribution-tiktok-js" */),
  "component---src-pages-music-distribution-youtube-music-js": () => import("./../../../src/pages/music-distribution/youtube-music.js" /* webpackChunkName: "component---src-pages-music-distribution-youtube-music-js" */),
  "component---src-pages-post-music-to-spotify-js": () => import("./../../../src/pages/post-music-to-spotify.js" /* webpackChunkName: "component---src-pages-post-music-to-spotify-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-providers-summary-js": () => import("./../../../src/pages/providers-summary.js" /* webpackChunkName: "component---src-pages-providers-summary-js" */),
  "component---src-pages-publish-music-on-spotify-js": () => import("./../../../src/pages/publish-music-on-spotify.js" /* webpackChunkName: "component---src-pages-publish-music-on-spotify-js" */),
  "component---src-pages-put-music-on-spotify-js": () => import("./../../../src/pages/put-music-on-spotify.js" /* webpackChunkName: "component---src-pages-put-music-on-spotify-js" */),
  "component---src-pages-release-music-to-spotify-js": () => import("./../../../src/pages/release-music-to-spotify.js" /* webpackChunkName: "component---src-pages-release-music-to-spotify-js" */),
  "component---src-pages-reset-password-email-sent-js": () => import("./../../../src/pages/reset-password-email-sent.js" /* webpackChunkName: "component---src-pages-reset-password-email-sent-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-reset-password-required-js": () => import("./../../../src/pages/reset-password-required.js" /* webpackChunkName: "component---src-pages-reset-password-required-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-smartlinks-js": () => import("./../../../src/pages/smartlinks.js" /* webpackChunkName: "component---src-pages-smartlinks-js" */),
  "component---src-pages-spotify-aggregator-js": () => import("./../../../src/pages/spotify-aggregator.js" /* webpackChunkName: "component---src-pages-spotify-aggregator-js" */),
  "component---src-pages-spotify-company-js": () => import("./../../../src/pages/spotify-company.js" /* webpackChunkName: "component---src-pages-spotify-company-js" */),
  "component---src-pages-spotify-distribution-js": () => import("./../../../src/pages/spotify-distribution.js" /* webpackChunkName: "component---src-pages-spotify-distribution-js" */),
  "component---src-pages-spotify-distributor-js": () => import("./../../../src/pages/spotify-distributor.js" /* webpackChunkName: "component---src-pages-spotify-distributor-js" */),
  "component---src-pages-spotify-label-js": () => import("./../../../src/pages/spotify-label.js" /* webpackChunkName: "component---src-pages-spotify-label-js" */),
  "component---src-pages-spotify-partner-js": () => import("./../../../src/pages/spotify-partner.js" /* webpackChunkName: "component---src-pages-spotify-partner-js" */),
  "component---src-pages-spotify-preferred-partner-js": () => import("./../../../src/pages/spotify-preferred-partner.js" /* webpackChunkName: "component---src-pages-spotify-preferred-partner-js" */),
  "component---src-pages-spotify-service-js": () => import("./../../../src/pages/spotify-service.js" /* webpackChunkName: "component---src-pages-spotify-service-js" */),
  "component---src-pages-submit-music-to-spotify-js": () => import("./../../../src/pages/submit-music-to-spotify.js" /* webpackChunkName: "component---src-pages-submit-music-to-spotify-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-upload-music-to-spotify-js": () => import("./../../../src/pages/upload-music-to-spotify.js" /* webpackChunkName: "component---src-pages-upload-music-to-spotify-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */)
}

